import {
  Box,
  Container,
  Flex,
  Heading,
  Stack,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import Head from "next/head";
import { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout.js";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import UnsupportedResolution from "../components/UnsupportedResolution.js";
dayjs.extend(duration);
import { motion, useAnimation } from "framer-motion";

const MotionBox = motion(Box);
const MotionImage = motion(Image);
const MotionText = motion(Text);

const motionDuration = 1;

const transitionSettings = {
  duration: motionDuration,
  delay: 2,
  type: "spring",
  stiffness: 50,
};

export default function Mobile() {
  const ref3 = useRef(null);
  const animationControls = useAnimation();
  async function sequence() {
    await animationControls.start({
      scale: 2,
      transition: {
        duration: 0.5,
        delay: 0.5,
      },
    });

    await animationControls.start({
      scale: 1.3,
      translateY: "-50%",
      transition: {
        duration: 1,
        delay: 1,
        type: "spring",
        stiffness: 50,
      },
    });
  }

  useEffect(() => {
    sequence();
  }, []);

  const ITSocialIcon = (props) => {
    return (
      <Link
        isExternal
        href={props.url}
        bgImage={`/${props.imageName}`}
        bgRepeat="no-repeat"
        bgSize="contain"
        height="20"
        width="20"
      />
    );
  };
  if (window.innerWidth < 360) return <UnsupportedResolution />;

  return (
    <Layout>
      <Head>
        <title>Impact Theory Scary Modz</title>
        <meta name="description" content="Impact Theory Scary Modz" />
        <link rel="icon" href="/favicon.png" />
        <meta property="og:title" content="Impact Theory Scary Modz" />
        <meta property="og:url" content="https://scarymodz.io" />
        {/* <meta property="og:image" content="https://impacttheory.com/wp-content/uploads/2018/11/impact-theory-logo.png" /> */}
      </Head>

      {/* Landing Section */}
      <Box
        priority="true"
        fontFamily="Typrighter-B"
        bgImage="/MainBG-mobile.jpg"
        bgSize="cover"
        bgRepeat="no-repeat"
        backgroundPosition="75% -0%"
        minHeight="2215px"
        width="100%"
        paddingTop={{ base: "5%", md: "0%" }}
      >
        <Container>
          <Flex flexDirection="column">
            <Box
              width="100%"
              position="relative"
              transform="auto"
              scale={{ base: "100%", lgPhone: "100%" }}
            >
              <MotionImage
                priority="true"
                src="/Logo@2x.png"
                objectFit="contain"
                transform="auto"
                initial={{ x: "0%", y: "70%", scale: 0.5 }}
                animate={animationControls}
                //scale="220%"
              />
              <MotionImage
                priority="true"
                src="/Max-box.png"
                objectFit="contain"
                transform="auto"
                initial={{ x: "-200%", y: "-35%", scale: 1.2 }}
                animate={{
                  translateX: "185%",
                }}
                transition={transitionSettings}
                zIndex="2"
              />
            </Box>
            <MotionBox
              initial={{ x: "200%", y: "0", scale: 1 }}
              animate={{
                translateX: "-200%",
              }}
              transition={{
                duration: motionDuration,
                delay: 2,
                type: "spring",
                stiffness: 65,
              }}
              width="100%"
              alignSelf="center"
            >
              <Box
                maxW="md"
                margin="auto"
                marginTop={{ base: "-25%", lg: "0" }}
              >
                <Text
                  fontFamily="Another-America"
                  transform="auto"
                  fontSize={{
                    base: "5rem",
                    smPhone: "3.4rem",
                    mdPhone: "3.7rem",
                    lgPhone: "4rem",
                    xlPhone: "5rem",
                  }}
                  textAlign="center"
                  letterSpacing="6.3px"
                  zIndex="overlay"
                  color="#E2FE01"
                >
                  FACE YOUR FEARS
                </Text>
                <Text
                  width="100%"
                  fontSize={{ base: "25px", sm: "21px", md: "25px" }}
                  letterSpacing="1px"
                >
                  Welcome the newest monsters to the Modz family.
                </Text>
                <Heading
                  fontSize={{
                    base: "2rem",
                    smPhone: "1.7rem",
                    mdPhone: "1.9rem",
                    lgPhone: "2rem",
                    xlPhone: "2.3rem",
                  }}
                  marginTop="-20px"
                  color="black"
                  bgSize="contain"
                  bgRepeat="no-repeat"
                  bgPos="left"
                  minHeight="135px"
                  paddingLeft="75px"
                  paddingTop="65px"
                  width="100%"
                  bgImage="/launchingearlybox.svg"
                  fontFamily="Typrighter-B"
                >
                  {`We're cooking.`}
                </Heading>
              </Box>
            </MotionBox>
          </Flex>
        </Container>
      </Box>

      {/* About Section  */}
      <MotionBox
        fontFamily="Typrighter-B"
        bgImage={{
          base: "/about-bg-mobile@2x.png",
          lgPhone: "/about-bg-ipad.png",
        }}
        bgSize="cover"
        minHeight={{ base: "1055px", xlPhone: "1460px" }}
        width="100%"
        transform="auto"
        zIndex="overlay"
        marginTop={{
          base: "-900px",
          smPhone: "-1400px",
          mdPhone: "-1350",
          lgPhone: "-1300",
          xlPhone: "-900",
        }}
        paddingTop="45%"
        position="relative"
        initial={{ y: "40%" }}
        animate={{
          translateY: "-40%",
        }}
        transition={transitionSettings}
      >
        <Container
          maxW={{ base: "sm", xlPhone: "2xl" }}
          transform="auto"
          translateY={{ base: "25%", xlPhone: "10%" }}
        >
          <Flex>
            <Box width="100%" alignSelf="center" pl="5%">
              <Box>
                {/* <MotionImage
                  animate={{ rotate: 28 }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 4,
                  }}
                  initial={{
                    x: 0,
                    y: 0,
                  }}
                  whileHover={{
                    scale: 1.3,
                    x: 0,
                    y: 0,
                    transition: { duration: 0.5 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  src="/blob.svg"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{ base: "-60%", "2xl": "-50%" }}
                  height={{ base: "28" }}
                  width="28"
                />
                <MotionImage
                  animate={{ rotate: 58 }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 4,
                  }}
                  initial={{
                    x: 0,
                    y: 0,
                  }}
                  whileHover={{
                    scale: 1.3,
                    x: 0,
                    y: 0,
                    transition: { duration: 0.5 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  src="/blob2.svg"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{ base: "-50%" }}
                  left={{ base: "15%" }}
                  height="8"
                  width="8"
                />
                <MotionImage
                  animate={{ rotate: -28 }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 4,
                  }}
                  initial={{
                    x: 0,
                    y: 0,
                  }}
                  whileHover={{
                    scale: 1.3,
                    transition: { duration: 0.5 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  src="/blob.svg"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{ base: "-60%" }}
                  left={{ base: "44%" }}
                  height="12"
                  width="12"
                />
                <MotionImage
                  animate={{ rotate: 18 }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 4,
                  }}
                  initial={{
                    x: 0,
                    y: 0,
                  }}
                  whileHover={{
                    scale: 1.3,
                    x: 0,
                    y: 0,
                    transition: { duration: 0.5 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  src="/blob3.svg"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{ base: "-50%" }}
                  left={{ base: "45%" }}
                  height="28"
                  width="28"
                />
                <MotionImage
                  animate={{ rotate: -78 }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 5,
                  }}
                  initial={{
                    x: 0,
                    y: 0,
                  }}
                  whileHover={{
                    scale: 1.3,
                    x: 0,
                    y: 0,
                    transition: { duration: 0.5 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  src="/blob2.svg"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{ base: "-45%" }}
                  left={{ base: "40%" }}
                  height="16"
                  width="16"
                />
                <MotionImage
                  animate={{ rotate: 38 }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 2,
                  }}
                  initial={{
                    x: 0,
                    y: 0,
                  }}
                  whileHover={{
                    scale: 1.3,
                    x: 0,
                    y: 0,
                    transition: { duration: 0.5 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  src="/blob.svg"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{ base: "-60%" }}
                  right={{ base: "20%" }}
                  height="28"
                  width="28"
                />
                <MotionImage
                  animate={{ rotate: 88 }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 7,
                  }}
                  initial={{
                    x: 0,
                    y: 0,
                  }}
                  whileHover={{
                    scale: 1.3,
                    x: 0,
                    y: 0,
                    transition: { duration: 0.5 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  src="/blob2.svg"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{ base: "-56%" }}
                  right={{ base: "15%" }}
                  height="16"
                  width="16"
                />
                <MotionImage
                  animate={{ rotate: 28 }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 3,
                  }}
                  initial={{
                    x: 0,
                    y: 0,
                  }}
                  whileHover={{
                    scale: 1.3,
                    x: 0,
                    y: 0,
                    transition: { duration: 0.5 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  src="/blob2.svg"
                  objectFit="contain"
                  transform="auto"
                  position="absolute"
                  top={{ base: "-73%" }}
                  right={{ base: "-2%" }}
                  height="16"
                  width="16"
                /> */}
                <MotionBox
                  initial={{ x: "-100%", hidden: true }}
                  whileInView={{
                    x: "0%",
                    hidden: false,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: motionDuration,
                    delay: 0.2,

                    ease: "easeOut",
                  }}
                >
                  <Heading
                    color="white"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    bgPos="left"
                    minHeight={{ base: "250px", xlPhone: "250px" }}
                    width={{ base: "200px", xlPhone: "350px" }}
                    paddingLeft={{ base: "45px", xlPhone: "80px" }}
                    paddingTop={{ base: "90px", xlPhone: "65px" }}
                    bgImage="/about-paint.svg"
                    fontFamily="Another-America"
                    fontSize={{ base: "3rem", xlPhone: "5.5rem" }}
                    letterSpacing="6.3px"
                  >
                    ABOUT
                  </Heading>
                </MotionBox>

                <MotionBox
                  initial={{ x: "-100%", hidden: true }}
                  whileInView={{
                    x: "0%",
                    hidden: false,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: motionDuration,
                    delay: 0.2,

                    ease: "easeOut",
                  }}
                  width="300px"
                  height="330px"
                >
                  <MotionBox
                    fontSize={{ base: "1rem", xlPhone: "1.3rem" }}
                    lineHeight={{ base: "1.5rem", xlPhone: "1.9rem" }}
                    initial={{ x: "-100%", hidden: true }}
                    whileInView={{
                      x: "0%",
                      hidden: false,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: motionDuration,
                      delay: 0.2,

                      ease: "easeOut",
                    }}
                    width="650px"
                    height="330px"
                  >
                    <Text>When the Monster World</Text>
                    <Text>falls under the rule of a horrible</Text>
                    <Text>creature that threatens their very</Text>
                    <Text>existence, a team of teenage monsters</Text>
                    <Text>travel to our world to kidnap the</Text>
                    <Text>offspring of a legendary human Monster</Text>
                    <Text>Hunter. But when they get home they </Text>
                    <Text>realizethe hero they thought they </Text>
                    <Text>recruited is a complete</Text>
                    <Text>total scaredy-cat.</Text>
                  </MotionBox>
                </MotionBox>
              </Box>
            </Box>
            <MotionImage
              width={{ base: "350px", xlPhone: "657.36px" }}
              height={{ base: "450px", xlPhone: "927.25px" }}
              position="absolute"
              marginTop={{ base: "-15%", xlPhone: "-40%" }}
              marginLeft={{ base:'7%', xlPhone: "10%" }}
              initial={{ x: "35%" }}
              whileInView={{
                x: "35%",
              }}
              viewport={{ root: ref3 }}
              transition={{
                duration: motionDuration,
                delay: 0.2,

                ease: "easeOut",
              }}
              src="/Hecto_main.png"
            />
          </Flex>
        </Container>
      </MotionBox>

      {/* Character Section  */}
      <Box
        fontFamily="Typrighter-B"
        bgImage="/CharachterLineup-BG-mobile.png"
        bgSize="cover"
        minHeight="3043px"
        bgRepeat="no-repeat"
        minW="100%"
        transform="auto"
        marginTop="-300px"
        paddingTop="25%"
        backgroundPosition="-200px -1343px"
      >
        <Container
          maxW="8xl"
          marginTop="125px !important"
          margin="auto"
          position="relative"
          height="800px"
          width="680px"
          transform="auto"
          scale={{
            base: "100%",
            smPhone: "60%",
            mdPhone: "65%",
            lgPhone: "70%",
            xlPhone: "100%",
          }}
          translateX={{
            smPhone: "-25%",
            mdPhone: "-25%",
            lgPhone: "-20%",
            xlPhone: "0",
          }}
        >
          <MotionImage
            initial={{ y: "-500px", opacity: 0 }}
            whileInView={{
              y: "0%",
              opacity: 1,
            }}
            viewport={{ root: ref3 }}
            transition={{
              duration: 1,
              delay: 0.5,
            }}
            src="/Blaze.png"
            objectFit="contain"
            transform="auto"
            position="absolute"
            bottom={{
              base: "0%",
              xxl: "0%",
              "1xl": "0%",
              "2xl": "0%",
            }}
            right={{ base: "-5%" }}
            height="1060px"
            width="1328px"
          />
          <MotionImage
            initial={{ y: "-50px", opacity: 0 }}
            whileInView={{
              y: "0%",
              opacity: 1,
            }}
            viewport={{ root: ref3 }}
            transition={{
              duration: 0.5,
              delay: 0.5,
            }}
            src="/Roxy.png"
            objectFit="contain"
            transform="auto"
            position="absolute"
            bottom={{
              base: "3%",
              xxl: "3%",
              "1xl": "3%",
              "2xl": "3%",
            }}
            left={{ base: "-10%" }}
            height="584px"
            width="531px"
          />
          <MotionImage
            initial={{ y: "200px", opacity: 0 }}
            whileInView={{
              y: "0px",
              opacity: 1,
            }}
            viewport={{ root: ref3 }}
            transition={{
              duration: 0.5,
              delay: 0.5,
            }}
            src="/Cloak.png"
            objectFit="contain"
            transform="auto"
            position="absolute"
            bottom={{
              base: "-3%",
              xxl: "-3%",
              "1xl": "-3%",
              "2xl": "-3%",
            }}
            right={{ base: "-25%" }}
            height="900px"
            width="774px"
          />
          <MotionImage
            initial={{ x: "50px", opacity: 0 }}
            whileInView={{
              x: "0px",
              opacity: 1,
            }}
            viewport={{ root: ref3 }}
            transition={{
              duration: 0.5,
              delay: 0.5,
            }}
            src="/Hecto.png"
            objectFit="contain"
            transform="auto"
            position="absolute"
            bottom={{
              base: "8%",
              xxl: "8%",
              "1xl": "8%",
              "2xl": "8%",
            }}
            right={{ base: "-2%" }}
            height="448px"
            width="299px"
          />

          <MotionImage
            initial={{ x: "200px", opacity: 0 }}
            whileInView={{
              x: "0px",
              opacity: 1,
            }}
            viewport={{ root: ref3 }}
            transition={{
              duration: 0.5,
              delay: 0.5,
            }}
            src="/Max.png"
            objectFit="contain"
            transform="auto"
            position="absolute"
            bottom={{
              base: "-15%",
              xxl: "-15%",
              "1xl": "-15%",
              "2xl": "-15%",
            }}
            right={{ base: "50%" }}
            marginRight="-40%"
            height="654px"
            width="506px"
          />
        </Container>
      </Box>

      {/* Meet the Modz Section  */}
      <Box
        fontFamily="Typrighter-B"
        minHeight={{
          smPhone: "4900px",
          mdPhone: "5000px",
          lgPhone: "5000px",
          xlPhone: "5350px",
          lg: "5600px",
        }}
        minW="100%"
        transform="auto"
        marginTop={{ base: "-2200px", xlPhone: "-2000px" }}
        paddingTop="75%"
        position="relative"
        overflow="hidden"
        bgImage="/MeetTheModzBG-mobile.svg"
        bgSize="cover"
      >
        <Container maxW="sm" zIndex="overlay" transform="auto">
          <Flex>
            <Stack m="auto" minW="sm">
              <Box position="relative" w="100%" h="200px" margin="auto">
                <Box
                  bgImage="/Meet-The-Modz-Paint.svg"
                  bgSize="contain"
                  bgRepeat="no-repeat"
                  minW="90%"
                  height="100%"
                  position="absolute"
                  left="%"
                  top="-18.5%"
                />
                <Box
                  bgImage="/Meet-The-Modz.svg"
                  bgSize="contain"
                  bgRepeat="no-repeat"
                  minW="70%"
                  height="100%"
                  position="absolute"
                  left="4"
                />
              </Box>
              <Stack width="100%" paddingTop={{ base: "0px", lg: "20px" }}>
                <Box position="relative" minW="50%" height="450px">
                  <MotionBox
                    bgImage="/Trace-paint.svg"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="100%"
                    height="440px"
                    bgPosition="center"
                    position="absolute"
                    top="-76px"
                    right="4%"
                    initial={{ x: "-80%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.2,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                  <MotionBox
                    bgImage="/Trace-bio-pic.png"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="50%"
                    height="450px"
                    transform="auto"
                    scale="120%"
                    bgPosition="center"
                    initial={{ x: "-80%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 1,
                      delay: 1,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                </Box>

                <MotionText
                  width="340px"
                  height="100%"
                  fontSize="20px"
                  letterSpacing=".3px"
                  initial={{ x: "80%", opacity: 0 }}
                  whileInView={{
                    x: "0%",
                    opacity: 1,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: 1,
                    delay: 0.2,

                    type: "spring",
                    stiffness: 65,
                    ease: "easeOut",
                  }}
                >
                  Son of the most famous Monster Hunter. Presumably bound to
                  follow in his father’s footsteps except for one thing—he’s a
                  complete and total scaredy-cat. Our team spirits him from the
                  human world into theirs because according to an ancient curse,
                  a human monster hunter is the only one who can kill the big
                  bad.
                </MotionText>
              </Stack>
              <Stack width="100%" paddingTop={{ base: "0px", lg: "20px" }}>
                <Box position="relative" minW="50%" height="450px">
                  <MotionBox
                    bgImage="/Cloak-paint.svg"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="100%"
                    height="540px"
                    bgPosition="center"
                    position="absolute"
                    top="-76px"
                    right="4%"
                    initial={{ x: "80%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.2,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                  <MotionBox
                    bgImage="/Cloak-bio-pic.png"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="50%"
                    height="450px"
                    transform="auto"
                    scale="140%"
                    bgPosition="center"
                    initial={{ x: "80%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 1,
                      delay: 1,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                </Box>

                <MotionText
                  width="340px"
                  height="100%"
                  fontSize="20px"
                  letterSpacing=".3px"
                  initial={{ x: "-80%", opacity: 0 }}
                  whileInView={{
                    x: "0%",
                    opacity: 1,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: 1,
                    delay: 0.2,

                    type: "spring",
                    stiffness: 65,
                    ease: "easeOut",
                  }}
                >
                  Our cloaked girl is a WITCH. This young emo-girl, breaks from
                  the tradition of most witches, she eschews things like eye of
                  newt, hair of frogs, and lame potions. Very sensitive, and
                  over-tuned into emotion, she’s shy in the real world, and
                  hides behind a digital image both online and IRL. Rather than
                  spells she can manifest digital powers
                </MotionText>
              </Stack>
              <Stack width="100%" paddingTop={{ base: "0px", lg: "20px" }}>
                <Box position="relative" minW="50%" height="450px">
                  <MotionBox
                    bgImage="/Hecto-paint.svg"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="100%"
                    height="440px"
                    bgPosition="center"
                    position="absolute"
                    top="-76px"
                    right="4%"
                    initial={{ x: "-80%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.2,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                  <MotionBox
                    bgImage="/Hecto-bio-pic.png"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="50%"
                    height="450px"
                    bgPosition="center"
                    transform="auto"
                    scale="120%"
                    initial={{ x: "-80%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 1,
                      delay: 1,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                </Box>
                <MotionText
                  width="340px"
                  height="100%"
                  fontSize="20px"
                  letterSpacing=".3px"
                  initial={{ x: "80%", opacity: 0 }}
                  whileInView={{
                    x: "0%",
                    opacity: 1,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: 1,
                    delay: 0.2,

                    type: "spring",
                    stiffness: 65,
                    ease: "easeOut",
                  }}
                >
                  Hekto IS a ghost, but has a problem with oozing excess
                  ectoplasm. It’s a teen thing. In spite of this he’s a pretty
                  cool and confident guy—mostly because he can’t be hurt as he’s
                  vapor.
                </MotionText>
              </Stack>
              <Stack width="100%" paddingTop={{ base: "0px", lg: "20px" }}>
                
                <Box position="relative" minW="50%" height="450px">
                  <MotionBox
                    bgImage="/Blaze-paint.svg"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="100%"
                    height="540px"
                    bgPosition="center"
                    position="absolute"
                    top="-76px"
                    right="4%"
                    initial={{ x: "80%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.2,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                  <MotionBox
                    bgImage="/Blaze-bio-pic.png"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="50%"
                    height="450px"
                    bgPosition="center"
                    transform="auto"
                    scale="130%"
                    initial={{ x: "80%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 1,
                      delay: 1,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                </Box><MotionText
                  width="340px"
                  height="100%"
                  fontSize="20px"
                  letterSpacing=".3px"
                  initial={{ x: "-80%", opacity: 0 }}
                  whileInView={{
                    x: "0%",
                    opacity: 1,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: 1,
                    delay: 0.2,

                    type: "spring",
                    stiffness: 65,
                    ease: "easeOut",
                  }}
                >
                  Abandoned at the monster orphanage, he grew up without a sense
                  of control over his power to grow big or not. It happens at
                  random which can be a huge problem. If the team needs muscle
                  and he’s small, they’re screwed. If the team is in a boat
                  crossing a river and he turns big, they sink. Etc. His
                  personal goal is to learn how to control his ability.
                </MotionText>
              </Stack>
              <Stack width="100%" paddingTop={{ base: "0px", lg: "20px" }}>
                <Box position="relative" minW="50%" height="450px">
                  <MotionBox
                    bgImage="/Roxy-paint.svg"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="100%"
                    height="500px"
                    bgPosition="center"
                    position="absolute"
                    top="-76px"
                    right="4%"
                    initial={{ x: "-80%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.2,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                  <MotionBox
                    bgImage="/Roxy-bio-pic.png"
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    minW="50%"
                    height="450px"
                    bgPosition="center"
                    transform="auto"
                    scale="120%"
                    initial={{ x: "-80%", opacity: 0 }}
                    whileInView={{
                      x: "0%",
                      opacity: 1,
                    }}
                    viewport={{ root: ref3 }}
                    transition={{
                      duration: 1,
                      delay: 1,

                      type: "spring",
                      stiffness: 65,
                    }}
                  />
                </Box>
                <MotionText
                   width="340px"
                   height="100%"
                   fontSize="20px"
                  letterSpacing=".3px"
                  initial={{ x: "80%", opacity: 0 }}
                  whileInView={{
                    x: "0%",
                    opacity: 1,
                  }}
                  viewport={{ root: ref3 }}
                  transition={{
                    duration: 1,
                    delay: 0.2,

                    type: "spring",
                    stiffness: 65,
                    ease: "easeOut",
                  }}
                >
                  Grew up loving Rock & Roll but as a gargoyle, she is supposed
                  to play only choir music. Her rebellious trait has made her an
                  outsider in her own family. Questions authority. Powers
                  include, flight, the ability to turn her body partially or
                  fully into stone (which allows regeneration from shattering,
                  camouflage, resistance to heat) and the power to ROCK (she can
                  amplify sound using her body). Traditionally, Gargoyles train
                  to serve a role in the human world, as watchful guardians. In
                  their guardian role, they monitor both human activity and any
                  illegal entry into the human world by monsters.
                </MotionText>
              </Stack>
            </Stack>
          </Flex>
        </Container>
      </Box>

      <Box
        width="100%"
        position="absolute"
        bottom="0px"
        height={{ base: "200px" }}
        maxWidth={{ base: "100%", xlPhone: "1920px" }}
        margin="auto"
      >
        <Stack alignItems="center">
          <Heading fontFamily="Typrighter-B">Stay Connected!</Heading>
          <Stack
            direction="row"
            spacing={{ base: 6, xlPhone: 24 }}
            align="center"
            pt="40px"
          >
            <ITSocialIcon
              url="https://discord.com/invite/impacttheory"
              imageName="Discord-footer.svg"
            />
          </Stack>
        </Stack>
      </Box>
    </Layout>
  );
}
