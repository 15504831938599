export const MenuItems = [
  {
    label: "PROJECTS",
    items: [
      { label: "Merry Modz", link: "https://merrymodz.io" },
      { label: "Project Kyzen", link: "https://projectkyzen.io" },
    ],
  },
  {
    label: "STORE",
    items: [
      { label: "Merry Modz", link: "https://opensea.io/collection/merry-modz" },
      { label: "Pre-Genesis", link: "https://tokentrove.com/collection/ProjectKyzenPreGenesis" },
      { label: "Relentless Lifemap", link: "https://opensea.io/collection/relentlesslifemap" },
    ],
  },
  {
    label: "WIKI",
    link: "https://wiki.founderskey.io/impact-theory-projects/secret-modz"
  }
];
