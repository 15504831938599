import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  useDisclosure,
  Stack,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  Icon,
  Collapse,
  Text,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { MenuItems } from "./MenuItems";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const NavLink = (props) => {
  return (
    <Link
      as={Link}
      isExternal
      bg="transparent"
      px={2}
      pt='.2rem'
      height="40px"
      fontSize="23px"
      filter={"drop-shadow(0 0 0.25rem rgb(66, 181, 14))"}
      borderRadius={20}
      _focus={{
        boxShadow: "none",
      }}
      _hover={{
        textDecoration: "none",
        bg: "radial-gradient(circle, rgba(163,207,33,1) 0%, rgba(255,255,255,1) 0%, rgba(163,207,33,1) 75%)",
        color: "black",
      }}
      _active={{
        bg: "green.400",
      }}
      href={props.link}
    >
      {props.name}
    </Link>
  );
};

const TopNavMenuList = (props) => {
  return (
    <MenuList bgColor={props.bgColor} borderWidth="0" boxShadow="none">
      {props.children}
    </MenuList>
  );
};

const TopNavMenuItem = (props) => {
  return (
    <MenuItem
      onClick={() => {
        if (props.target === "current") {
          location.href = props.link;
        } else {
          window.open(props.link);
        }
      }}
      _focus={{ bg: "transparent" }}
      _hover={{ bg: "transparent" }}
      filter={"drop-shadow(0 0 0.25rem rgb(255, 217, 0))"}
    >
      {props.name}
    </MenuItem>
  );
};

const TopNavMenuButton = (props) => {
  return (
    <MenuButton
      as={Button}
      rightIcon={<ChevronDownIcon />}
      height="40px"
      fontSize="23px"
      letterSpacing="3px"
      filter={"drop-shadow(0 0 0.25rem rgb(66, 181, 14))"}
      bg="transparent"
      borderRadius="20"
      color={"#fff"}
      _focus={{
        boxShadow: "none",
      }}
      _hover={{
        bg: "radial-gradient(circle, rgba(163,207,33,1) 0%, rgba(255,255,255,1) 0%, rgba(163,207,33,1) 75%)",
        color: "black",
      }}
      _active={{
        bg: "radial-gradient(circle, rgba(163,207,33,1) 0%, rgba(255,255,255,1) 0%, rgba(163,207,33,1) 75%)",
        color: "black",
      }}
    >
      {props.label}
    </MenuButton>
  );
};

const WrapperMenuItem = (props) => {
  return (
    <Menu>
      <TopNavMenuButton label={props.label} />
      <TopNavMenuList
        bgColor="black"
        borderWidth="0"
        borderRadius="64px"
        boxShadow="none"
      >
        {props.items.map((item, i) => (
          <TopNavMenuItem key={i} link={item.link} name={item.label} />
        ))}
      </TopNavMenuList>
    </Menu>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      borderRadius="xl"
      display={{ xxl: "none" }}
    >
      {MenuItems.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, items, link }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={items && onToggle} width="300px">
      <Flex
        margin="auto"
        width="100%"
        py={2}
        as={Link}
        isExternal={link ?? false}
        href={link ?? "#"}
        justify={"space-between"}
        align={"center"}
        borderRadius="3xl"
        _hover={{
          textDecoration: "none",
        }}
        _active={{
          bg: "radial-gradient(circle, rgba(163,207,33,1) 0%, rgba(255,255,255,1) 0%, rgba(163,207,33,1) 75%)",
          color: "black",
        }}
        bg={
          isOpen
            ? "radial-gradient(circle, rgba(163,207,33,1) 0%, rgba(255,255,255,1) 0%, rgba(163,207,33,1) 75%)"
            : ""
        }
      >
        <Text fontWeight={600} margin="auto" color={isOpen ? "black" : "white"}>
          {label}
        </Text>
        {items && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor="gray.700"
          align={"start"}
        >
          {items &&
            items.map((child) => (
              <Link key={child.label} py={2} href={child.link} isExternal>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const ITSocialIcon = (props) => {
  return (
    <Link
      isExternal
      href={props.url}
      bgImage={`/${props.imageName}`}
      bgRepeat="no-repeat"
      bgSize="contain"
      height="9"
      width="9"
    />
  );
};

export default function ITNavBar(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <MotionBox
      initial={{ x: "0", y: "-100%", scale: 1 }}
      animate={{
        translateY: "100%",
      }}
      transition={{
        duration: 1,
        delay: 2,
      }}
      px={4}
      fontFamily="Typrighter-B"
      bg="transparent"
      position="absolute"
      top="0"
      left={{ lg: "35%", "2xl": "45%", "3xl": "45%" }}
      right={{ base: "0%", xxl: "unset" }}
      maxWidth="1920px"
      margin="auto"
      zIndex="overlay"
    >
      <Flex h={24} justifyContent={"flex-end"} marginRight={"5%"}>
        <IconButton
          size={"lg"}
          icon={
            isOpen ? (
              <CloseIcon
                height={{ base: "35px", xlPhone: "50px" }}
                paddingLeft={{ base: "25px", xlPhone: "25px" }}
                width={{ base: "50px", xlPhone: "75px" }}
                marginTop={{ base: "10px", xlPhone: "15px" }}
                transform="auto"
                zIndex="overlay"
              />
            ) : (
              <HamburgerIcon
                height={{ base: "35px", xlPhone: "75px" }}
                width={{ base: "35px", xlPhone: "75px" }}
                marginTop="10px"
              />
            )
          }
          aria-label={"Open Menu"}
          display={{ xxl: "none" }}
          onClick={isOpen ? onClose : onOpen}
          backgroundColor="transparent"
          borderWidth="0"
        />
        <HStack spacing={8} alignItems={"center"}>
          {/* <Box>Logo</Box> */}
          <HStack
            as={"nav"}
            spacing={12}
            display={{ base: "none", xxl: "flex" }}
          >
            {MenuItems.map((link, i) =>
              link.items ? (
                <WrapperMenuItem
                  key={i}
                  label={link.label}
                  items={link.items}
                />
              ) : (
                <NavLink key={i} link={link.link} name={link.label}></NavLink>
              )
            )}
          </HStack>
          <HStack
            paddingLeft="100px"
            display={{ base: "none", xxl: "flex" }}
            spacing={12}
          >
            <ITSocialIcon
              url="https://discord.com/invite/impacttheory"
              imageName="Discord.svg"
            />
          </HStack>
        </HStack>
        {/* <Flex alignItems={'center'}>
                        <ITMenu />
                    </Flex> */}
      </Flex>
      {isOpen ? <MobileNav /> : null}
    </MotionBox>
  );
}
